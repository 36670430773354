/* Font Import for Apple Style */
@import url('https://fonts.googleapis.com/css2?family=SF+Mono:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: 'SF Mono', 'Menlo', 'Monaco', 'Courier New', monospace;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: all 0.3s ease-in-out;
}

:root {
    --background-color: #f5f5f7;
    --text-color: #1d1d1f;
    --link-color: #ff6b6b;
    /* Salmon pink */
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #1d1d1f;
        --text-color: #f5f5f7;
        --link-color: #ff6b6b;
        /* Salmon pink */
    }
}

a {
    color: var(--link-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

header {
    text-align: center;
    position: relative;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

h1,
h2 {
    font-weight: 700;
}

main section {
    margin-bottom: 40px;
}

ul {
    list-style-type: none;
    padding: 0;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 24px;
}

.social-links a {
    color: var(--text-color);
}

.social-links a:hover {
    color: var(--link-color);
}